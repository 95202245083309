<template>
  <div class="vipMain">
    <div class="backHome">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      我发布的房屋列表
    </div>
    <div v-if="house_list.length == 0" class="pic" style="text-align: center">
      <img src="../../assets/img/noData.png" alt="" />
    </div>

    <div id="components-demo" class="wp">
      <ul class="ul-list1-hlg" id="house_list" v-for="item in house_list" :id="item.id">
        <li class="istradefalse house_info" @click="toDetails(item.id)">
          <div class="pic">
            <a>
              <img :src="item.img_url" alt="" />
              <em class="start displayfalse"></em>
            </a>
          </div>
          <div class="txt">
            <h3 class="first">
              <a>
                <em class="e1 displaytrue"></em>
                <font style="">{{ item.housename }}</font>
              </a>
            </h3>
            <p class="t1">
              {{ item.room }}室{{ item.hall }}厅{{ item.toilet }}卫 /
              {{ item.housearea }}㎡
            </p>
            <div class="info info0">
              <div class="left">
                <span v-if="item.type == '出售'">{{item.type}}</span>
                <span v-else style="color:#ff0000;">{{item.type}}</span>
                <span>{{ item.status =='3' ?'上架':'下架' }}</span><span>{{ item.add_date }}</span>
              </div>
              <p class="right">{{ item.price }}万元</p>
            </div>
          </div>
          <i class="suc displayfalse"></i> <em class="bao displayfalse"></em>
        </li>
      </ul>
    </div>
    <div class="demo-block"></div>
    <div class="bottomBtn" @click="toAdd()">新增房屋</div>
    <div class="bg_1"></div>
  </div>
</template>

<script>
  import "../../../public/css/info-mb.css";
  // import '../../../public/css/nav.css';
  import "../../../public/css/custom.css";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  export default {
    data() {
      return {
        sitenum: "",
        openid: "",
        house_list: [],
      };
    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return	;
      }
      this.getHouseList(); //获取自己创建的房屋信息
      this.getWxConfig(); //加载js
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      back(where) {
        //顶部后退和返回按钮
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prev") {
          this.$router.back();
        }
      },
      toDetails(id) {
        this.$router.push({
          name: "myHouse",
          query: {
            id: id,
            type: "edit",
          },
        });
      },
      toAdd(id) {
        this.$router.push({
          name: "myHouse",
          query: {
            type: "add",
          },
        });
      },
      async getHouseList() {
        //获取自己创建的房屋信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/postmysql.ashx";
        var param = {
          act: "getHouseList",
          type: "mylist",
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              var sData = {
                id: data[i][0],
                img_url: data[i][2],
                title: data[i][1],
                add_date: data[i][9],
                price: data[i][3],
                housearea: data[i][8],
                room: data[i][4],
                hall: data[i][5],
                toilet: data[i][6],
                balcony: data[i][7],
                status: data[i][10], //'3正常销售  4 不想卖了  5售出
                type: data[i][12], //出租,出售
                housename: data[i][13],
                // kh: data[i].kh,
              };
              that.house_list.push(sData);
            }
          }
        } else {
          this.$router.push("login");
        }
      },
    },
    components: {},
  };
</script>

<style scoped lang="less">
  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #42bd56;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
